import { translate } from 'vue-gettext';

const { gettext: $gettext } = translate;

export default {
  purpose: [
    { text: $gettext('Paying invoices'), value: 'bills' },
    { text: $gettext('Payment of salaries'), value: 'salaries' },
    { text: $gettext('Taxes'), value: 'taxes' },
    { text: $gettext('Investment'), value: 'investment' },
    { text: $gettext('Refinancing of a previous debt'), value: 'debts' },
    { text: $gettext('Other'), value: 'other' },
  ],
};
